@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy");
@import url("https://fonts.cdnfonts.com/css/ponnala");
@import url("https://fonts.googleapis.com/css2?family=Poppins");

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #001525;
}

.welcome_home {
  padding-left: 70px;
  background-color: #001525;
}
.w_header {
  font-family: "Luckiest Guy";
  font-size: 100px;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
}

.alchemist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 85vh;
  /* background-color: yellow; */
}

.alchemist_left {
  width: 50%;
  display: flex;
  align-items: center;
  /* background-color: red; */
}

.alchemist_right {
  width: 50%;
  /* background-color: green; */
  display: flex;
  justify-content: center;
}

.w_para {
  color: #ffffff;
  font-family: "ponnala";
  font-size: 24px;
  margin-top: 17px;
  padding-right: 250px;
}

.btns {
  display: flex;
  align-items: center;
  gap: 35px;
  margin-top: 11px;
}

.btns_width {
  width: 300px;
}

.btnMain {
  padding: 12px 15px;
  color: #001525;
  font-family: "poppins";
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}

.btnMain:hover {
  background-color: #001525;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.btnSec {
  padding: 12px 15px;
  color: #ffffff;
  font-family: "poppins";
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
  background-color: #001525;
  border: none;
  cursor: pointer;
}

.btnSec:hover {
  background-color: #ffffff;
  color: #001525;
  border: 1px solid #001525;
}

.who_is_ekene {
  background-color: #09396c;
  height: auto;
  width: 100%;
  padding-top: 87px;
  padding-bottom: 87px;
}

.who_is_ekene_header {
  text-align: center;
  color: #ffffff;
  font-family: "Luckiest Guy";
  margin-bottom: 87px;
}

.who_is_ekene_content {
  display: flex;
  align-items: center;
}
.who_is_ekene_content_left {
  width: 50%;
  padding-left: 70px;
  height: 100%;
  font-family: "ponnala";
}

.who_is_ekene_content_left p {
  color: #ffffff;
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 27px;
}
.who_is_ekene_content_right {
  width: 50%;
  display: flex;
  justify-content: center;
}

.banner {
  padding-top: 54px;
  padding-bottom: 54px;
  background-color: #ffffff;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  gap: 100px;
}

.banner_content_img {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.banner_content_p {
  font-family: "ponnala";
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

.projects {
  background-color: #001525;
  padding-top: 140px;
  padding-bottom: 140px;
}

.projects_header {
  text-align: center;
  color: #ffffff;
  font-family: "Luckiest Guy";
  margin-bottom: 87px;
}

.projects_header_content {
  margin: 0;
  display: grid;
  /* grid-template-areas: 'a, b';
  grid-auto-columns: 100px; */
  grid-auto-flow: column;
  grid-template-rows: 550px 550px;
  place-items: center;
  height: auto;
}

.projects_header_item {
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  width: 563px;
  height: 482px;
  align-items: center;
}

.projects_header_item_main {
}

.projects_header_image {
  height: 50%;
}

.btns_welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 122px;
}

.testimonials {
  background-color: #bcc1d1;
  width: 100%;
  height: auto;
  padding-top: 110px;
  padding-bottom: 110px;
  margin-bottom: 211px;
}

.testimonials_header {
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-family: "poppins";
  text-align: center;
  margin-bottom: 55px;
}

.entre {
  margin-bottom: 132px;
  background-color: #ffffff;
  padding-top: 53px;
}

.tech_trainer_header {
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-family: "poppins";
  text-align: center;
  margin-bottom: 52px;
  color: #ffffff;
}

.tech_trainer {
  margin-bottom: 132px;
  background-color: #09396c;
  padding-top: 53px;
}

.tech_trainer_con {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tech_trainer_p {
  font-size: 24px;
  font-weight: 400;
  font-family: "ponnala";
  color: #ffffff;
  padding-right: 80px;
}

.entre_header {
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-family: "poppins";
  text-align: center;
  margin-bottom: 52px;
  color: #000000;
}

.testimonials_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonials_container_words {
  width: 80%;
  background-image: url("./../../Assets/images/png/Rectangletestimonal.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 10px;
}

.testimonials_text {
  font-size: 20px;
  font-weight: 400;
  font-family: "ponnala";
  margin-bottom: 38.28px;
}

.testimonials_by {
  font-size: 24px;
  font-weight: 400;
  font-family: "ponnala";
}

.testimonials_btn {
  margin: 0 auto;
  width: 85%;
  margin-top: 27px;
  display: flex;
  gap: 32px;
}

.entre_con {
  display: flex;
  justify-content: center;
  align-items: center;
}

.entre_p {
  font-size: 24px;
  font-weight: 400;
  font-family: "ponnala";
  color: #001525;
  padding-left: 80px;
}

.contact {
  display: flex;
  align-items: center;
  padding-left: 48px;
  background-color: #09396c;
  padding-top: 92px;
  padding-bottom: 66px;
}

.contact_header {
  font-family: "Luckiest Guy";
  font-size: 70px;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
}

.contact_left {
  width: 60%;
}

.contact_right {
  width: 40%;
}

.contact_right_img {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 78px;
}

.contact_right_img p {
  font-size: 32px;
  font-family: "poppins";
  font-weight: 600;
  color: #ffffff;
}

@media (max-width: 768px) {
  .welcome_home {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    padding-top: 100px;
  }
  .w_header {
    font-family: "Luckiest Guy";
    font-size: 60px;
    font-weight: 400;
    font-style: normal;
    color: #ffffff;
  }

  .alchemist {
    display: block;
    height: auto;
    width: 100%;
  }

  .alchemist_left {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    /* background-color: red; */
  }

  .alchemist_right {
    width: 90%;
    display: flex;
    justify-content: center;
  }

  .w_para {
    color: #ffffff;
    font-family: "ponnala";
    font-size: 14px;
    margin-top: 17px;
    padding-right: 20px;
  }

  .alchemist_right_photo {
    display: block;
    width: 100%;
  }

  .alchemist_right_photo_img {
    width: 100%;
  }

  .btns {
    display: block;
  }

  .btns_width {
    margin-bottom: 20px;
  }

  .who_is_ekene {
    background-color: #09396c;
    height: auto;
    width: 100%;
    padding-top: 87px;
    padding-bottom: 87px;
  }

  .who_is_ekene_header {
    text-align: center;
    color: #ffffff;
    font-family: "Luckiest Guy";
    margin-bottom: 87px;
    font-size: 30px;
  }

  .who_is_ekene_content {
    display: block;
  }
  .who_is_ekene_content_left {
    font-family: "ponnala";
    padding-left: 20px;
    width: 92%;
  }

  .who_is_ekene_content_left p {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 27px;
  }
  .who_is_ekene_content_right {
    width: 100%;
    display: flex;
    justify-content: center;
    /* background-color: red; */
  }

  .banner {
    padding-top: 54px;
    padding-bottom: 54px;
    background-color: #ffffff;
    width: 100%;
    height: auto;
    display: block;
  }

  .banner_content_img {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  .banner_content_p {
    font-family: "ponnala";
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }
  .projects {
  }

  .projects {
    background-color: #001525;
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .projects_header {
    text-align: center;
    color: #ffffff;
    font-family: "Luckiest Guy";
    margin-bottom: 87px;
  }

  .projects_header_content {
    /* margin: 0; */
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 150px 150px;
    /* place-items: center; */
    /* height: auto; */
  }

  .projects_header_item {
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    width: 100px;
    height: 100px;
    align-items: center;
  }

  .projects_header_image_img {
    width: 100%;
  }

  .projects_header_image {
    width: 60px;
    height: 60px;
  }

  .contact {
    display: block;
    padding-left: 20px;
    background-color: #09396c;
    padding-top: 92px;
    padding-bottom: 66px;
  }

  .contact_header {
    font-family: "Luckiest Guy";
    font-size: 40px;
    font-weight: 400;
    font-style: normal;
    color: #ffffff;
  }

  .contact_left {
    width: 60%;
    margin-bottom: 50px;
  }

  .contact_right {
    width: 40%;
  }

  .contact_right_img {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 78px;
  }

  .contact_right_img p {
    font-size: 14px;
    font-family: "poppins";
    font-weight: 600;
    color: #ffffff;
  }

  .contact_right_img_img {
    width: 20px;
    height: 20px;
  }

  .entre {
    margin-bottom: 132px;
    background-color: #ffffff;
    padding-top: 53px;
  }

  .entre_con {
    display: block;
  }

  .entre_p {
    font-size: 14px;
    font-weight: 400;
    font-family: "ponnala";
    color: #001525;
    padding-left: 20px;
  }

  .entre_con_img {
    width: 100%;
  }

  .tech_trainer_header {
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    font-family: "poppins";
    text-align: center;
    margin-bottom: 52px;
    color: #ffffff;
  }

  .tech_trainer {
    margin-bottom: 132px;
    background-color: #09396c;
    padding-top: 53px;
  }

  .tech_trainer_con {
    display: block;
    padding-bottom: 10px;
  }

  .tech_trainer_p {
    font-size: 14px;
    font-weight: 400;
    font-family: "ponnala";
    color: #ffffff;
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .testimonials_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .testimonials_container_words {
    width: 80%;
    height: auto;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 10px;
  }

  .testimonials_text {
    font-size: 14px;
    font-weight: 400;
    font-family: "ponnala";
    margin-bottom: 38.28px;
  }

  .testimonials_by {
    font-size: 14px;
    font-weight: 400;
    font-family: "ponnala";
  }

  .testimonials_btn {
    margin: 0 auto;
    width: 85%;
    margin-top: 27px;
    display: flex;
    gap: 32px;
  }

  .testimonials {
    background-color: #bcc1d1;
    width: 100%;
    height: auto;
    padding-top: 110px;
    padding-bottom: 110px;
    margin-bottom: 211px;
  }

  .testimonials_header {
    font-size: 30px;
    font-weight: 600;
    font-style: normal;
    font-family: "poppins";
    text-align: center;
    margin-bottom: 55px;
  }

  .btnSec {
    padding: 8px 10px;
    color: #ffffff;
    font-family: "poppins";
    font-weight: 600;
    font-size: 12px;
    border-radius: 5px;
    background-color: #001525;
    border: none;
    cursor: pointer;
  }

  .btnSec:hover {
    background-color: #ffffff;
    color: #001525;
    border: 1px solid #001525;
  }

  .btnMain {
    padding: 8px 10px;
    color: #001525;
    font-family: "poppins";
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
  }

  .btnMain:hover {
    background-color: #001525;
    color: #ffffff;
    border: 1px solid #ffffff;
  }
}
