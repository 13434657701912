@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy");
@import url("https://fonts.cdnfonts.com/css/ponnala");
@import url("https://fonts.googleapis.com/css2?family=Poppins");

* {
  margin: 0;
  padding: 0;
}

.about_main {
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 195px;
  padding-bottom: 95px;
  color: #ffffff;
}

.about_main_intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 64px;
  padding-bottom: 60px;
  margin-top: 70px;
}

.about_main_left {
  width: 50%;
  display: flex;
  justify-content: center;
}

.about_main_right {
  width: 50%;
}

.about_main_header {
  font-family: "Luckiest Guy";
  font-size: 64px;
  font-weight: 400;
}
.about_main_p {
  font-family: "Ponnala";
  font-size: 24px;
  font-weight: 400;
}

.about_toggle_btn {
  width: 271px;
  background-color: #ffffff;
  height: auto;
}

.about_toggle_btn {
  color: #000000;
  display: flex;
  padding: 10px;
  font-family: "poppins";
  justify-content: space-between;
  cursor: pointer;
  border-radius: 20px;
  font-weight: 500;
  font-size: 24px;
}

.about_middle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 60px;
}

.banner_about {
  padding-top: 54px;
  padding-bottom: 54px;
  background-color: #ffffff;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  gap: 100px;
}

.about_testimony {
  width: 90%;
  margin-left: 40px;
}

.about_testimony_main {
  /* background-color: yellow; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  cursor: pointer;
  margin-top: 50px;
  padding-left: 40px;
  width: 15%;
}
.about_testimony_btn {
  /* background-color: red; */
}

@media (max-width: 768px) {

  .about_main_img{
    width: 100%;
    
  }
  .about_main_left_con{
    width: 100%;
    margin-bottom: 20px;
  }
  .about_main {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 100px;
    padding-bottom: 95px;
    color: #ffffff;
  }

  .about_main_intro {
    display: block;
    padding-bottom: 60px;
    margin-top: 70px;
  }

  .about_main_left {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .about_main_right {
    width: 100%;
  }

  .about_main_header {
    font-family: "Luckiest Guy";
    font-size: 30px;
    font-weight: 400;
  }
  .about_main_p {
    font-family: "Ponnala";
    font-size: 14px;
    font-weight: 400;
  }

  .about_toggle_btn {
    width: 271px;
    background-color: #ffffff;
    height: auto;
  }

  .about_toggle_btn {
    color: #000000;
    display: flex;
    padding: 10px;
    font-family: "poppins";
    justify-content: space-between;
    cursor: pointer;
    border-radius: 20px;
    font-weight: 500;
    font-size: 24px;
  }

  .about_middle {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 60px;
  }

  .banner_about {
    padding-top: 54px;
    padding-bottom: 54px;
    background-color: #ffffff;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    gap: 100px;
  }

  .about_testimony {
    width: 90%;
    margin-left: 15px;
  }

  .about_testimony_main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    cursor: pointer;
    margin-top: 50px;
    padding-left: 40px;
    width: 15%;
  }

  .about_test{
  }
}
