@import url("https://fonts.googleapis.com/css2?family=Poppins");

* {
  margin: 0;
  padding: 0;
}

.navbar_home {
  background-color: #001525;
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  height: auto;
  align-items: center;
  height: 80px;
}

.left_navbar {
  display: flex;
  gap: 97px;
  align-items: center;
}

.nav_bar {
  display: flex;
  gap: 20px;
}

.logo {
  width: 50px;
  height: 50px;
}

.right_navbar {
  display: flex;
  align-items: center;
  gap: 50px;
}

.socials {
  display: flex;
  align-items: center;
  gap: 30px;
}

.list {
  list-style: none;
}
.anchor {
  text-decoration: none;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}

.anchor_down {
  text-decoration: none;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  color: #ffffff;
}

.anchor:hover {
  color: #193868;
}

.version {
  color: #193868;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
}

.menu_btn {
  display: none;
}

.menu_btn_icon {
  width: 20px;
  height: 20px;
  color: #ffffff;
}

.navbar_mobile {
  /* display: none; */
}

.drop_down_menu {
  position: absolute;
  left: 358px;
  top: 80px;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 2px;
  text-decoration: none;
  background-color: #193868;
  padding: 12px;
}

@media (max-width: 768px) {
  .navbar_home {
    background-color: #001525;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    height: 80px;
  }

  .logo {
    width: 20px;
    height: 20px;
  }

  .menu_btn {
    display: block;
  }
  .nav_bar {
    display: none;
  }

  .menu_btn_icon {
    width: 25px;
    height: 25px;
    color: #ffffff;
  }

  .socials {
    gap: 11px;
    /* display: none; */
  }
  .social_icon {
    width: 15px;
    height: 15px;
  }

  .right_navbar {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .navbar_mobile {
    position: absolute;
    top: 80px;
    background-color: #09396c;
    padding-left: 20px;
    padding-top: 20px;
    width: 100%;
    height: 700px;
  }

  .drop_down_menu {
    position: absolute;
    cursor: pointer;
    font-weight: 700;
    background-color: #000000;
    left: 40px;
    top: 180px;
  }

  /* .nav_bar_mobile_links{
    margin-bottom: 20px;
  } */
}
