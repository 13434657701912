@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy");
@import url("https://fonts.cdnfonts.com/css/ponnala");
@import url("https://fonts.googleapis.com/css2?family=Poppins");

* {
  margin: 0;
  padding: 0;
}

.same_main {
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 195px;
  padding-bottom: 70px;
}

.same_main_header {
  font-family: "Luckiest Guy";
  font-size: 64px;
  font-weight: 400;
  color: #ffffff;
}

.same_main_p {
  font-family: "Ponnala";
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  letter-spacing: 1px;
}

.same_content {
  display: flex;
  justify-content: center;
  gap: 60px;
  margin-bottom: 60px;
  color: #ffffff;
  font-family: "Ponnala";
  font-size: 24px;
  letter-spacing: 1px;
  align-items: center;
}

.same_content_left {
  width: 50%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.same_content_right {
  width: 50%;
  display: flex;
  justify-content: center;
}

.same_content_img {
  width: 100%;
}
@media (max-width: 768px) {
  .same_main {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 100px;
    padding-bottom: 70px;
  }

  .same_main_header {
    font-family: "Luckiest Guy";
    font-size: 30px;
    font-weight: 400;
    color: #ffffff;
  }

  .same_main_p {
    font-family: "Ponnala";
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 1px;
  }

  .same_content {
    display: block;
    margin-bottom: 30px;
    color: #ffffff;
    font-family: "Ponnala";
    font-size: 14px;
    letter-spacing: 1px;
  }

  .same_content_left {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    /* background-color: red; */
  }
  .same_content_right {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .same_content_img {
    width: 100%;
  }

  .same_content_left_img {
    width: 60%;
    height: 80%;
  }
}
