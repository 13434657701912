@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy");
@import url("https://fonts.cdnfonts.com/css/ponnala");
@import url("https://fonts.googleapis.com/css2?family=Poppins");

* {
  margin: 0;
  padding: 0;
}

.projects_main_header {
  padding-left: 70px;
  padding-right: 70px;
  text-align: center;
  margin-top: 192px;
}

.projects_main_search {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.projects_main_header_text {
  font-family: "poppins";
  font-size: 48px;
  font-weight: 600;
  font-style: normal;
  color: #ffffff;
  margin-bottom: 79px;
}

.projects_search {
  width: 80%;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.projects_filter {
  width: 15%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  letter-spacing: 2px;
  font-family: "poppins";
  font-weight: 300;
  cursor: pointer;
  background-color: #ffffff;
}

.projects_search_icon {
  width: 25px;
  height: 25px;
}

.projects_search_bar {
  width: 90%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  height: 20px;
  letter-spacing: 2px;
  font-family: "poppins";
  outline: none;
}

.projects_search_bar:focus {
  border: none;
}

.projects_filter_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.projects_filter_drop {
  width: 214px;
  height: 233px;
  right: 90px;
  margin-top: 20px;
  background-color: #ffffff;
  width: 15%;
  border-radius: 20px;
  position: absolute;
}

.projects_filter_drop_drown {
  margin-bottom: 10px;
  margin-top: 10px;
  font-family: "poppins";
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.projects_filter_drop_drown:hover {
  background-color: #001525;
  color: #ffffff;
}

.project_header_content {
  margin-top: 60px;
  grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
  grid-auto-rows: 550px;
  place-items: center;
  height: auto;
}

.project_header_item {
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  width: 563px;
  height: 482px;
  align-items: center;
  cursor: pointer;
}

.project_header_item:hover {
  border: 5px solid green;
}

.project_header_content_desc {
  padding-left: 70px;
  margin-top: 100px;
  padding-right: 70px;
  background-color: #09396c;
  padding-top: 78px;
  padding-bottom: 78px;
  margin-bottom: 300px;
}

.project_header_content_p {
  font-family: "poppins";
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 15px;
}

.project_header_content_link {
  font-family: "poppins";
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 15px;
}

.project_header_content_h1 {
  font-family: "Luckiest Guy";
  font-size: 30px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .projects_main_header {
    padding-left: 20px;
    padding-right: 70px;
    text-align: center;
    margin-top: 100px;
  }

  .projects_main_search {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .projects_main_header_text {
    font-family: "poppins";
    font-size: 30px;
    font-weight: 600;
    font-style: normal;
    color: #ffffff;
    margin-bottom: 50px;
  }

  .projects_search {
    width: 80%;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .projects_filter {
    width: auto;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    letter-spacing: 2px;
    font-family: "poppins";
    font-weight: 300;
    cursor: pointer;
    background-color: #ffffff;
  }

  .projects_search_icon {
    width: 25px;
    height: 25px;
  }

  .projects_search_bar {
    width: 90%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    height: 10px;
    letter-spacing: 1px;
    font-family: "poppins";
    outline: none;
  }

  .projects_search_bar:focus {
    border: none;
  }

  .projects_filter_inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .projects_filter_drop {
    height: auto;
    right: 10px;
    margin-top: 20px;
    background-color: #ffffff;
    width: 40%;
    border-radius: 10px;
    position: absolute;
  }

  .projects_filter_drop_drown {
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: "poppins";
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .projects_filter_drop_drown:hover {
    background-color: #001525;
    color: #ffffff;
  }

  .project_header_content {
    margin-top: 60px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 320px;
    place-items: center;
    height: auto;
  }

  .project_header_item {
    background-color: #ffffff;
    border-radius: 20px;
    display: block;
    width: 300px;
    height: 300px;
    align-items: center;
    cursor: pointer;
  }

  .project_header_item:hover {
    border: 5px solid green;
  }

  .project_header_content_desc {
    padding-left: 70px;
    margin-top: 100px;
    padding-right: 70px;
    background-color: #09396c;
    padding-top: 78px;
    padding-bottom: 78px;
    margin-bottom: 300px;
  }

  .project_header_content_p {
    font-family: "poppins";
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 15px;
  }

  .project_header_content_link {
    font-family: "poppins";
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 15px;
  }

  .project_header_content_h1 {
    font-family: "Luckiest Guy";
    font-size: 30px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 15px;
  }

  .projects_header_image {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .projects_header_image_img {
    width: 70%;
  }
}
